import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../../helmet';

const Head = ({
  metaTitle,
                metaDescription = '',
                metaCanonical = '',
  imageUrl,
  children,
}) => {
  const seoTags = [
    {
      tagName: 'title',
      content: metaTitle,
    },
    {
      tagName: 'meta',
      attributes: { property: 'og:title', content: metaTitle },
    },
    {
      tagName: 'meta',
      attributes: { name: 'description', content: metaDescription },
    },
    {
      tagName: 'meta',
      attributes: { property: 'og:description', content: metaDescription },
    },
    {
      tagName: 'meta',
      attributes: { property: 'og:image', content: imageUrl },
    },
  ];

  return (
    <Helmet
      tags={{ tags: seoTags }}
      metaRobots=""
      metaCanonical={metaCanonical}
      ogType="product"
      title={metaTitle}
      description={metaDescription}
    >
      {process.env.GATSBY_IMGPROXY_BASEURL && (
        <link rel="preconnect" href={process.env.GATSBY_IMGPROXY_BASEURL} />
      )}

      {imageUrl && (
        <link rel="preload" href={imageUrl} as="image" />
      )}

      {children}
    </Helmet>
  );
};

Head.defaultProps = {
  imageUrl: undefined,
  metaDescription: '',
  metaCanonical: '',
  children: null,
};

Head.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  metaCanonical: PropTypes.string,
  imageUrl: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Head;
