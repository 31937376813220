/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.scss';
import './src/styles/css/global.css';
import './src/styles/css/components.css';

import React from 'react';
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from 'react-query';

import Page from './src/components/page';

const queryClient = new QueryClient();

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }) => (
    <QueryClientProvider client={queryClient}>
        <Page>
            {element}
        </Page>
    </QueryClientProvider>
);
