import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import GuestbookHeader from '../guestbook-components/guestbook-header';
import GuestbookComments from '../guestbook-components/guestbook-comments';
import GuestForm from '../guestbook-components/guest-form';
import Paginations from '../component-elements/pagination';
import Breadcrumb from '../component-elements/breadcrumb';
import Header from '../component-elements/header';
import Helmet from '../components/helmet';
import CmsBrandContactComponent from '../content-elements/brandContact';
import ContentHeaderCms from '../content-elements/contentHeaderCms';

const components = {
    DatoCmsTextSimple: ContentHeaderCms,
    DatoCmsBrandContact: CmsBrandContactComponent,
};

const GuestBookPage = ({
    data: {
        allDatoCmsGuestbook: {
            nodes: comments,
        },
        datoCmsConfig: {
            pagePrivacy: privacyPage,
        },
        datoCmsPage: page,
    },
    pageContext: { currentPage, numGuestPage, lang: locale, url },
    location: { pathname },
}) => (
    <Layout textcolorHeader="black" textcolorHeaderMobile="black">
        <Breadcrumb
            items={[
                { id: page.id, name: page.name },
            ]}
        />

        {page.header[0] && (
            <Header data={page.header[0]} />
        )}

        {!page.header[0] && (
            <GuestbookHeader />
        )}

        {(page.content || []).map((singleComponent) => {
            if ((typeof singleComponent === 'object') === true && Object.keys(singleComponent).length > 0) {
                const Component = components[singleComponent.__typename];
                return (
                    <Component key={singleComponent.__typename} {...singleComponent} />
                );
            }
            return (
                <div><h4>This content element can not be rendered.</h4></div>
            );
        })}

        <GuestForm locale={locale} privacyLink={privacyPage.fullUrlPath} />

        <GuestbookComments comments={comments} />

        <Paginations totalPageCount={numGuestPage} activePage={currentPage} path={pathname} />

        <Helmet
            tags={page.seoMetaTags}
            metaRobots={page.metaRobots}
            metaCanonical={page.metaCanonical}
            title={page.seo?.title}
            titleFallback={t('cms_page_title_fallback', page.name)}
            url={url}
        />
    </Layout>
);

export default GuestBookPage;

GuestBookPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query GuestBookQuery($url: String, $lang: String, $skip: Int = 0, $limit: Int = 1) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        ...content
        metaCanonical
        metaRobots
    }

    allDatoCmsGuestbook(
        skip: $skip, 
        limit: $limit, 
        filter: {releasedGuestbook: {eq: true}, locale: {eq: $lang}}
        sort: {fields: dateGuestbook, order: DESC}
    ) {
        nodes {
            id
            message: messageGuestbook
            name: nameGuestbook
            title: titleGuestbook
            date: dateGuestbook(formatString: "D.M.YYYY")
        }
    }

    datoCmsConfig(locale: {eq: $lang}) {
        pagePrivacy {
            fullUrlPath
        }
    }
}
`;
